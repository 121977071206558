<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">EggStock</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Edit</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
	
    <b-form method="post" @submit="addEggStock">
      <b-card>
        <b-row>
          <b-col md="6">
            <b-form-group label="Purchase Date " label-for="Purchase-Date ">
              <b-form-datepicker
                name="purchase_date"
                v-model="FormData.purchase_date"
                menu-class="w-100"
                calendar-width="100%"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Source" label="Source">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.source"
                  placeholder="Source"
                  type="text"
                  name="source"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Flock id" label-for="Flock id">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.flock_id"
                  placeholder="Flock id"
                  type="text"
                  name="flock_id"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Breed" label="Breed">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.breed"
                  placeholder="Breed"
                  type="text"
                  name="breed"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Batch id" label="Batch id">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.batch_id"
                  placeholder="Batch id"
                  type="text"
                  name="batch_id"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="Egg Qty" label="Egg Qty">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.egg_qty"
                  placeholder="Egg Qty"
                  type="number"
                  name="egg_qty"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Flock Age" label="Flock Age">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.flock_age"
                  placeholder="Flock Age"
                  type="number"
                  name="flock_age"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Price Per Egg" label="Price Per Egg">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.price_per_egg"
                  placeholder="Price Per Egg"
                  type="number"
                  name="price_per_egg"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Damaged" label="Damaged">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.damaged"
                  placeholder="Damaged"
                  type="text"
                  name="damaged"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Egg laid date " label-for="Egg laid Date ">
              <b-form-datepicker
                v-model="FormData.egg_laid_date"
                name="egg_laid_date"
                menu-class="w-100"
                calendar-width="100%"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
  },

  data() {
    return {
      FormData: {
        purchase_date: null,
        flock_id: null,
        breed: null,
        batch_id: null,
        egg_qty: null,
        flock_age: null,
        price_per_egg: null,
        damaged: null,
        egg_laid_date: null,
        source: null,
      },
    };
  },
  methods: {
    addEggStock(e) {
      e.preventDefault();
      const hatchery_id = this.$route.params.hatcheryId
      var data = new FormData();
      data.append("flock_id", this.FormData.flock_id);
      data.append("breed", this.FormData.breed);
      data.append("batch_id", this.FormData.batch_id);
      data.append("egg_qty", this.FormData.egg_qty);
      data.append("flock_age", this.FormData.flock_age);
      data.append("price_per_egg", this.FormData.price_per_egg);
      data.append("damaged", this.FormData.damaged);
      data.append("purchase_date", this.FormData.purchase_date);
      data.append("egg_laid_date", this.FormData.egg_laid_date);
      data.append("source", this.FormData.source);
      data.append("company_id", this.$route.params.companyId);
      data.append("hatchery_id", this.$route.params.hatcheryId);
      axiosIns
        .post(`web/hatchery/${hatchery_id}/purchaseEgg`, data)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `data Successfully Added `,
            },
          });
          setTimeout(
            this.$router.push({
              path: `/apps/purchase`,
            }),
            3000
          );
        })
        .catch((error) => {
          (error.response);
          const data = error.response.data.errors;
          (data, "data");
          let arr = Object.values(data).flat().toString(",");

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
